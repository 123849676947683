<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <div v-if="null !== lookups">
      <v-row v-for="(ntbType, index) in lookups.ntb_types" :key="index">
        <v-col cols="12">
          <v-card
            elevation="0"
            @click="selectNtbType(index, index === 'other')"
            :class="{
              'tile-selected':
                isSelected(index) ||
                (index === 'other' && step1.other_selected),
            }"
          >
            <img :src="getImage(ntbType)" />
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="step1.other_selected">
        <v-col cols="12">
          <v-autocomplete
            solo
            clearable
            :items="typeList"
            v-model="step1.ntb_type_id"
            :label="t('Select other NTB type')"
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-subtitle
                    @click="selectNtbType(data.item.value, true)"
                    v-html="data.item.text"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="hasError('ntb_type_id')">
        <v-col cols="12">
          <div class="error--text theme--light">
            <span
              v-for="message in getErrorMessages('ntb_type_id')"
              :key="message"
            >
              {{ message }} <br />
            </span>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty, forEach, filter } from "lodash";
import MessageAlert from "../../../components/alert/MessageAlert";

export default {
  name: "WizardStep1",
  components: {
    MessageAlert,
  },
  emits: ["values-changed", "set-title"],
  props: {
    ntbTypeSelected: {
      type: String,
      default: null,
    },
    otherSelected: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      title: "SELECT NTB TYPE",
      step: 1,
      step1: {
        ntb_type_id: null,
        other_selected: false,
      },
    };
  },
  computed: {
    ...mapState({
      lookups: (state) => state.lookups,
      lang: (state) => state.lang,
    }),
    typeList() {
      const that = this;
      let list = [];

      forEach(this.lookups.ntb_types_all, function (data) {
        if (isEmpty(data.parent_id)) {
          let children = filter(that.lookups.ntb_types_all, function (o) {
            return o.parent_id === data.id;
          });

          if (!isEmpty(children)) {
            list.push({
              header: data.name,
            });

            forEach(children, function (child) {
              list.push({
                text: child.heading_number + " - " + child.name,
                value: child.id,
                group: data.name,
              });
            });

            list.push({
              divider: true,
            });
          }
        }
      });

      return list;
    },
  },
  mounted() {
    this.step1.ntb_type_id = this.ntbTypeSelected;
    this.step1.other_selected = this.otherSelected;
    this.$emit("values-changed", { data: this.step1, step: this.step });
    this.$emit("set-title", { title: this.title });
  },
  methods: {
    hasError(field) {
      return !isEmpty(this.errors[field]);
    },
    getErrorMessages(field) {
      return this.errors[field];
    },
    selectNtbType(id, other) {
      if (other) {
        this.selectOther();
      } else {
        this.step1.other_selected = other;
        this.step1.ntb_type_id = id;
        this.$emit("values-changed", { data: this.step1, step: this.step });
      }
    },
    isSelected(id) {
      return this.step1.ntb_type_id === id;
    },
    selectOther() {
      this.step1.ntb_type_id = null;
      this.step1.other_selected = true;
    },
    getImage(ntbType) {
      const key = "img_" + this.lang;
      return process.env.VUE_APP_API_URL + "images/" + ntbType[key];
    },
  },
};
</script>

<style scoped>
.tile-selected {
  background-color: #000000 !important;
  border: solid #000000 !important;
  border-width: 10px 10px 5px 10px !important;
}
div >>> .v-subheader {
  font-weight: bold !important;
}
</style>
