import Vue from "vue";
import VueRouter from "vue-router";
import AboutView from "../views/AboutView.vue";
import FaqView from "../views/FaqView.vue";
import LoginView from "../views/LoginView.vue";
import RegisterView from "../views/RegisterView";
import ForgotPasswordView from "../views/ForgotPasswordView";
import DashboardView from "../views/DashboardView.vue";
import ComplaintsView from "../views/complaints/ComplaintsView";
import ComplaintLog from "../views/complaints/ComplaintLog";
import ProfileView from "../views/ProfileView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/about",
    name: "about",
    component: AboutView,
    meta: {
      title: "About Us",
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: FaqView,
    meta: {
      title: "Frequently Asked Questions",
    },
  },
  {
    path: "/login",
    name: "login",
    alias: "/",
    component: LoginView,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      title: "Register",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: {
      title: "Dashboard",
    },
  },
  {
    path: "/complaints/view",
    name: "complaints-view",
    component: ComplaintsView,
    meta: {
      title: "View Complaints",
    },
  },
  {
    path: "/complaints/log/:id?",
    name: "complaints-log",
    component: ComplaintLog,
    meta: {
      title: "Log a Complaint",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
    meta: {
      title: "Forgot Password",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: {
      title: "Profile",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
