<template>
  <v-container :class="['pt-0', showFloatingButtons ? 'mb-16' : '']">
    <div></div>
    <overlay-custom
      class="pt-0"
      :active="loading"
      :message="loadingMessage"
    ></overlay-custom>
    <div ref="scrollstartposition"></div>
    <v-row v-if="!loading">
      <v-col cols="12" class="pt-0">
        <div class="stepper-box mb-5">
          <div class="row">
            <v-col
              cols="2"
              class="text-center justify-center align-self-center pa-5"
            >
              <v-icon x-large
                >mdi-numeric-{{ currentStep }}-circle-outline</v-icon
              >
            </v-col>
            <v-col cols="10" class="pa-5">
              <span class="font-weight-bold text-uppercase">{{
                t(currentStepTitle)
              }}</span>
              <v-progress-linear
                :value="currentStepCompletion"
                :color="completionColour"
                height="25"
                rounded
                class="mt-2"
              >
                <template>
                  <strong>{{ currentStepCompletion }} %</strong>
                </template>
              </v-progress-linear>
              <div class="text-right mt-1">
                <span class="font-weight-light"
                  >{{ t("Step") }} {{ currentStep }}
                  {{ t("of") }}
                </span>
                <span class="font-weight-bold">{{ totalSteps }}</span>
              </div>
            </v-col>
          </div>
        </div>
        <v-icon
          v-if="currentStep > 1"
          x-large
          class="float-left"
          @click="backward"
          color="primary"
          >mdi-arrow-left-circle</v-icon
        >
        <v-icon
          @click="validateSave"
          class="float-right"
          x-large
          color="primary"
          >{{
            lastStep ? "mdi-check-circle-outline" : "mdi-arrow-right-circle"
          }}</v-icon
        >
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card elevation="2">
          <wizard-step1
            v-if="currentStep === 1"
            @values-changed="mapValues"
            @set-title="setTitle"
            :ntb-type-selected="getData('ntb_type_id', 1)"
            :other-selected="getData('other_selected', 1)"
            :errors="errors"
            :error-message="errorMessage"
          ></wizard-step1>
          <wizard-step2
            v-if="currentStep === 2"
            @values-changed="mapValues"
            @set-title="setTitle"
            :country-selected="getData('country_id', 2)"
            :date-selected="getData('date_of_incident', 2)"
            :location-selected="getData('location_id', 2)"
            :location-type-selected="getData('location_type_id', 2)"
            :location-other="getData('location_other', 2)"
            :errors="errors"
            :error-message="errorMessage"
          ></wizard-step2>
          <wizard-step3
            v-if="currentStep === 3"
            @values-changed="mapValues"
            @remove-doc="removeDoc"
            @set-title="setTitle"
            :attached-docs="attachedDocs"
            :complaint-entered="getData('complaint', 3)"
            :documents-entered="getData('documents', 3)"
            :errors="errors"
            :error-message="errorMessage"
            :is-editing="isEditing"
          ></wizard-step3>
          <wizard-step4
            v-if="currentStep === 4"
            @values-changed="mapValues"
            @set-title="setTitle"
            :product-description-entered="getData('product_description', 4)"
            :errors="errors"
            :error-message="errorMessage"
          ></wizard-step4>
          <wizard-step5
            v-if="currentStep === 5"
            @values-changed="mapValues"
            @set-title="setTitle"
            :value-entered="getData('value', 5) + ''"
            :currency-selected="getData('currency_id', 5)"
            :errors="errors"
            :error-message="errorMessage"
          ></wizard-step5>
          <wizard-complete
            v-if="lastStep"
            @set-title="setTitle"
            :complaint-info="dataToSubmit"
            :error-message="errorMessage"
          ></wizard-complete>
          <v-icon
            v-if="showFloatingButtons && currentStep !== 1"
            x-large
            fixed
            bottom
            left
            color="primary"
            class="float-back-button"
            @click="backward"
            @scroll="onScroll"
            >mdi-arrow-left-circle</v-icon
          >
          <v-icon
            v-if="showFloatingButtons"
            fixed
            bottom
            right
            x-large
            color="primary"
            class="float-next-button"
            @click="validateSave(true)"
            @scroll="onScroll"
            >{{
              lastStep ? "mdi-check-circle-outline" : "mdi-arrow-right-circle"
            }}</v-icon
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import WizardStep1 from "./wizard/Step1";
import WizardStep2 from "./wizard/Step2";
import WizardStep3 from "./wizard/Step3";
import WizardStep4 from "./wizard/Step4";
import WizardStep5 from "./wizard/Step5";
import WizardComplete from "./wizard/Complete";
import {
  isUndefined,
  forEach,
  omit,
  isNil,
  forOwn,
  find,
  isNull,
} from "lodash";
import { mapErrors } from "@/lib/formUtils";
import OverlayCustom from "../../components/OverlayCustom";

export default {
  name: "ComplaintLog",
  components: {
    OverlayCustom,
    WizardStep1,
    WizardStep2,
    WizardStep3,
    WizardStep4,
    WizardStep5,
    WizardComplete,
  },
  data() {
    return {
      currentStep: 1,
      currentStepTitle: null,
      totalSteps: 6,
      dataToSubmit: {},
      errorMessage: null,
      errors: [],
      loading: false,
      loadingMessage: null,
      showFloatingButtons: false,
      fieldDependencies: {
        location_type_id: "location",
      },
      attachedDocs: [],
      editingMapData: {
        1: ["ntb_type_id", "other_selected"],
        2: [
          "country_id",
          "date_of_incident",
          "location_id",
          "location_type_id",
          "location_other",
        ],
        3: ["complaint"],
        4: ["product_description"],
        5: ["value", "currency_id"],
      },
    };
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.userDetails,
      token: (state) => state.token,
      lookups: (state) => state.lookups,
      lang: (state) => state.lang,
    }),
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
    }),
    lastStep() {
      return this.currentStep === this.totalSteps;
    },
    isEditing() {
      return !isUndefined(this.$route.params.id);
    },
    complaintId() {
      return isUndefined(this.$route.params.id) ? null : this.$route.params.id;
    },
    currentStepCompletion() {
      const stepToUse = this.currentStep - 1,
        totalToUse = this.totalSteps - 1;

      return Math.ceil((stepToUse / totalToUse) * 100);
    },
    completionColour() {
      return "grey";
    },
    shortenedLang() {
      return this.lang.substring(0, 2);
    },
  },
  mounted() {
    if (!this.isLoggedIn) {
      this.$router.push({
        name: "login",
        query: {
          popup: "true",
          message: this.t("Unauthorized!"),
          type: "error",
        },
      });
    }
  },
  created() {
    const that = this;
    window.addEventListener("wheel", this.onScroll);

    if (this.isEditing) {
      const complaintId = this.$route.params.id;
      this.loadingMessage = this.t("Loading complaint...");
      this.loading = true;
      this.$store
        .dispatch("viewComplaint", {
          complaintId: complaintId,
          token: this.token,
        })
        .then((response) => {
          that.attachedDocs = response.data.data.documents;
          forEach(that.editingMapData, function (fields, step) {
            that.dataToSubmit[step] = {};
            forEach(fields, function (field) {
              if (!isUndefined(that.fieldDependencies[field])) {
                that.dataToSubmit[step][field] = !isNull(
                  response.data.data[that.fieldDependencies[field]]
                )
                  ? response.data.data[that.fieldDependencies[field]][field]
                  : "";
              } else {
                that.dataToSubmit[step][field] = response.data.data[field];
              }
            });
          });

          if (isNull(response.data.data.location)) {
            that.dataToSubmit[2]["location_type_id"] = find(
              that.lookups.location_types,
              function (o) {
                return o.name.toLowerCase() === "other";
              }
            ).id;
          }
        })
        .catch((error) => {
          mapErrors(error.data);
        })
        .finally(() => {
          that.loading = false;
        });
    }
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.onScroll);
  },
  methods: {
    onScroll() {
      this.showFloatingButtons = window.scrollY > 200;
    },
    toTop() {
      this.$refs.scrollstartposition.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      this.showFloatingButtons = false;
    },
    clearErrors() {
      this.errorMessage = null;
      this.errors = [];
    },
    forward() {
      this.currentStep++;
      this.clearErrors();
    },
    backward() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }

      this.clearErrors();
    },
    removeDoc(data) {
      this.attachedDocs = data.listDocs;
    },
    mapValues(data) {
      this.dataToSubmit[data.step] = data.data;

      if (!isNil(data.formData)) {
        this.dataToSubmit[data.step]["formData"] = data.formData;
      }
    },
    setTitle(data) {
      this.currentStepTitle = data.title;
    },
    getData(field, stepId) {
      return isUndefined(this.dataToSubmit[stepId])
        ? null
        : this.dataToSubmit[stepId][field];
    },
    mapErrors(errorDetails) {
      this.errors = mapErrors(errorDetails);
      this.errorMessage = this.t(errorDetails.message);
    },
    async validateSave(toTop = false) {
      const that = this;
      that.loading = true;
      that.loadingMessage = that.lastStep
        ? this.t("Logging Complaint...")
        : this.t("Validating Complaint...");
      let stepDataToSubmit = {};
      that.errorMessage = null;
      that.errors = [];

      if (this.lastStep) {
        let finalFormData = new FormData();

        forEach(this.dataToSubmit, function (data, key) {
          if (parseInt(key) !== that.totalSteps) {
            if (!isNil(data.formData)) {
              for (let formDataInfo of data.formData.entries()) {
                finalFormData.append(formDataInfo[0], formDataInfo[1]);
              }
            } else {
              forOwn(data, function (value, field) {
                finalFormData.append(field, value);
              });
            }
          }
        });

        finalFormData.append("user_id", this.userDetails.id);
        finalFormData.append("submit", true);

        if (this.isEditing) {
          finalFormData.append("id", this.complaintId);
        }

        stepDataToSubmit = finalFormData;
      } else {
        stepDataToSubmit = this.dataToSubmit[this.currentStep];
        stepDataToSubmit = omit(stepDataToSubmit, "user_id");
        stepDataToSubmit = omit(stepDataToSubmit, "submit");

        if (this.isEditing) {
          stepDataToSubmit["id"] = this.complaintId;
        }

        if (!isNil(this.dataToSubmit[this.currentStep]["formData"])) {
          stepDataToSubmit = this.dataToSubmit[this.currentStep]["formData"];

          if (this.isEditing) {
            stepDataToSubmit.append("id", this.complaintId);
          }
        }
      }

      this.$store
        .dispatch("wizard", {
          data: stepDataToSubmit,
          token: this.token,
        })
        .then((response) => {
          if (response.data.data.next_step) {
            that.forward();
          }

          if (response.data.data.completed) {
            toTop = false;

            that.$router.push({
              name: "complaints-view",
              query: {
                popup: "true",
                message: this.t(
                  this.isEditing ? "Complaint updated!" : "Complaint created!"
                ),
                type: "info",
              },
            });
          }
        })
        .catch((error) => {
          that.mapErrors(error.data);
        })
        .finally(() => {
          that.loading = false;
          that.loadingMessage = null;
          if (toTop) {
            that.toTop();
          }
        });
    },
  },
};
</script>

<style scoped>
.v-stepper--alt-labels .v-stepper__step {
  flex-basis: 75px !important;
}
.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 35px -22px 0px !important;
}
.v-stepper__step--complete >>> span {
  background-color: #12a012 !important;
}
.active-step >>> span {
  background-color: red !important;
}
.float-next-button {
  bottom: 75px !important;
  right: 25px !important;
  position: fixed;
  margin-bottom: 20px;
}
.float-back-button {
  bottom: 75px !important;
  left: 25px !important;
  position: fixed;
}
.stepper-box {
  border: 1px solid #cccccc;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 10px 10px -4px rgba(0, 0, 0, 0.57);
  height: auto;
}
</style>
