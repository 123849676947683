var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(_vm.errorMessage)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('message-alert',{attrs:{"message":_vm.errorMessage,"alert-type":"error"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.currencyList,"label":_vm.t('Currency'),"error-messages":_vm.getErrorMessages('currency_id')},model:{value:(_vm.step5.currency_id),callback:function ($$v) {_vm.$set(_vm.step5, "currency_id", $$v)},expression:"step5.currency_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field-money',{attrs:{"label":_vm.t('Value of consignment'),"properties":{
          readonly: false,
          disabled: false,
          outlined: false,
          clearable: true,
          errorMessages: _vm.getErrorMessages('value'),
        },"options":{
          length: 10,
          precision: 2,
          empty: '0',
        }},on:{"change":_vm.change},model:{value:(_vm.step5.value),callback:function ($$v) {_vm.$set(_vm.step5, "value", $$v)},expression:"step5.value"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }