<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img
          alt="EAC"
          :src="require('@/assets/logo.png')"
          max-width="200"
          contain
          class="mx-auto"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="2">
          <v-card-title class="py-1">{{
            t("The NTB Mobile App")
          }}</v-card-title>
          <v-card-text>
            <p>
              {{
                t(
                  "This application has been designed to allow users who are citizens of the COMESA, EAC and SADC Regional Economic Communities (RECs) to report Non-Tariff Barriers (NTBs). Users that encounter NTBs whilst trading or trying to trade within these regions can log a complaint using the mobile app and the complaint will be sent to the relevant REC for review."
                )
              }}
            </p>
            <p>
              {{
                t(
                  "The app is available for use by the following COMESA, EAC and SADC countries: Angola, Botswana, Burundi, Comoros, Democratic Republic of the Congo, Djibouti, Egypt, Eritrea, Eswatini, Ethiopia, Kenya, Lesotho, Libya, Madagascar, Malawi, Mauritius, Mozambique, Namibia, Rwanda, Seychelles, Somalia, South Africa, South Sudan, Sudan, Tanzania, Tunisia, Uganda, Zambia and Zimbabwe."
                )
              }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="2">
          <v-card-title class="py-1">{{
            t("How to log a complaint")
          }}</v-card-title>
          <v-card-text>
            <p>
              {{
                t(
                  "This app allows you to log and track a complaint that you think might be an NTB."
                )
              }}
            </p>

            <p>
              {{
                t(
                  "During the process of logging a complaint you will be asked a few questions - please be assured that your anonymity will be ensured, and data protected."
                )
              }}
            </p>

            <p>
              {{
                t(
                  "The information you provide will be sent to the relevant REC administrator who will then work to resolve the issue."
                )
              }}
            </p>

            <p>
              {{
                t(
                  "You can track the status of your complaint using this app through the Dashboard screen. You may log new complaints at anytime."
                )
              }}
            </p>
            <p>
              {{ t("For more information refer to the FAQ section.") }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AboutView",
};
</script>
