import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#000000",
        blue: "#4e73df",
        yellow: "#f6c23e",
      },
    },
  },
});

export default vuetify;
