<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <v-row v-if="!ready">
      <v-col cols="12">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <div v-if="ready">
      <v-row>
        <v-col cols="12">
          <message-alert
            :message="t('Welcome back') + ' ' + userDetails.first_name + '!'"
            alert-type="success"
          />
        </v-col>
      </v-row>
      <v-row dense class="mt -0 mb-3">
        <v-col cols="12">
          <v-card
            color="#f2a41f"
            @click="$router.push('/complaints/view')"
            class="border-radius py-4"
            elevation="12"
          >
            <v-row>
              <v-col cols="9" class="d-flex pl-5">
                <v-card-title
                  class="text-h6 font-weight-bold align-self-center pr-0"
                >
                  {{ t("View My Complaints") }}
                </v-card-title>
              </v-col>
              <v-col cols="3" class="align-self-center text-center ml-n4">
                <v-icon class="white--text large-icon">mdi-card-search</v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense class="my-3">
        <v-col cols="12">
          <v-card
            class="border-radius py-4"
            color="#299347"
            dark
            @click="$router.push('/complaints/log')"
            elevation="12"
          >
            <v-row>
              <v-col cols="9" class="d-flex pl-5">
                <v-card-title
                  class="text-h6 font-weight-bold align-self-center pr-0"
                >
                  {{ t("Create New Complaint") }}
                </v-card-title>
              </v-col>
              <v-col cols="3" class="align-self-center text-center ml-n4">
                <v-icon class="large-icon">mdi-plus-circle</v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense class="my-3">
        <v-col cols="12">
          <v-card
            class="border-radius py-4"
            color="#c3a366"
            @click="changeLanguage"
            elevation="12"
          >
            <v-row>
              <v-col cols="9" class="d-flex pl-5">
                <v-card-title
                  class="black--text text-h6 font-weight-bold align-self-center"
                >
                  {{ t("Change Language") }}
                </v-card-title>
              </v-col>
              <v-col cols="3" class="align-self-center text-center ml-n4">
                <v-icon color="white" class="large-icon">mdi-translate</v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import MessageAlert from "../components/alert/MessageAlert";

export default {
  name: "DashboardView",
  components: { MessageAlert },
  emits: ["language-change"],
  data() {
    return {
      errorMessage: null,
      ready: false,
    };
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.userDetails,
    }),
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
    }),
  },
  async mounted() {
    const that = this;
    that.errorMessage = null;

    if (this.isLoggedIn) {
      this.$store
        .dispatch("userDetails")
        .then(() => {})
        .catch((error) => {
          this.errorMessage = error.data.message;
        })
        .finally(() => {
          that.ready = true;
        });
    } else {
      await that.$router.push({
        name: "login",
        query: {
          popup: "true",
          message: that.t("Unauthorized!"),
          type: "error",
        },
      });
    }
  },
  methods: {
    changeLanguage() {
      this.$emit("language-change");
    },
  },
};
</script>

<style scoped>
.top >>> .v-icon {
  border-radius: 50%;
  border: 4px solid #dadada;
  padding: 10px;
  margin-top: -50px;
  background-color: #ffffff;
}
.bottom {
  cursor: pointer;
}
.bg-orange {
  background-color: #ffa71c;
}
.bg-blue {
  background-color: #4996e2;
}
.bg-green {
  background-color: #68c66c;
  border: solid #68c66c 2px;
}
div >>> .v-card__title {
  background-color: unset !important;
  color: unset !important;
  word-break: unset !important;
  border: unset !important;
}
div >>> .v-card__text {
  padding-top: unset !important;
}
.border-radius {
  border-radius: 32px;
}
.large-icon {
  font-size: 64px !important;
}
</style>
