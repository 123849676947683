/* eslint-disable */

const ara = {
    "Languages": "اللغات",
    "Complaints": "شكاوي",
    "Create": "يخلق",
    "No internet connection": "لا يوجد اتصال بالإنترنت",
    "About Us": "معلومات عنا",
    "Login": "تسجيل الدخول",
    "Register": "يسجل",
    "Dashboard": "لوحة القيادة",
    "View Complaints": "عرض الشكاوى",
    "Log a Complaint": "تسجيل شكوى",
    "Forgot Password": "هل نسيت كلمة السر",
    "Profile": "حساب تعريفي",
    "NTB Type": "نوع إن تي بي",
    "Date of Incident": "تاريخ الحادث",
    "Country/Region of Incident": "البلد/منطقة الحادث",
    "Type of Location": "نوع الموقع",
    "Location": "موقع",
    "Complaint": "شكوى",
    "Tariff Code": "رمز التعريفة",
    "Cost/Value of goods in USD": "تكلفة/قيمة البضائع بالدولار الأمريكي",
    "Occurrence": "حادثة",
    "Time lost": "الوقت الضائع",
    "Money lost in USD": "الأموال المفقودة بالدولار الأمريكي",
    "REVIEW COMPLAINT SUMMARY": "مراجعة ملخص الشكوى",
    "Select other NTB type": "حدد نوع NTB آخر",
    "SELECT NTB TYPE": "حدد نوع NTB",
    "Incident Date": "تاريخ الحادث",
    "Other Location": "موقع آخر",
    "SELECT DATE AND LOCATION": "اختر التاريخ والموقع",
    "Complaint Details (max 300 words)": "تفاصيل الشكوى (بحد أقصى 300 كلمة)",
    "Please grant the app the photos/files permissions to upload evidence": "يرجى منح التطبيق أذونات الصور/الملفات لتحميل الأدلة",
    "TYPE COMPLAINT": "نوع الشكوى",
    "Word count": "عدد الكلمات",
    "GO BACK": "عُد",
    "SELECT TARIFF CODE & USD": "حدد رمز التعريفة والدولار الأمريكي",
    "Occurrences in the last 12 months?": "الأحداث في الأشهر الـ 12 الماضية؟",
    "Time lost as a result of this incident?": "الوقت الضائع نتيجة لهذا الحادث؟",
    "Money lost as a result of this incident in USD?": "الأموال المفقودة نتيجة لهذا الحادث بالدولار الأمريكي؟",
    "OCCURRENCES": "الحوادث",
    "Previous": "سابق",
    "Log Complaint": "سجل الشكوى",
    "Next": "التالي",
    "Logging Complaint": "تسجيل شكوى",
    "Validating Complaint": "التحقق من صحة الشكوى",
    "Complaint created!": "تم إنشاء الشكوى!",
    "Complaint updated!": "تم تحديث الشكوى!",
    "Notifications": "إشعارات",
    "Important message to all users": "رسالة هامة لجميع المستخدمين",
    "No Complaints": "لا يوجد شكاوى",
    "View My Complaints": "عرض شكواي",
    "View": "منظر",
    "Status": "حالة",
    "Country": "دولة",
    "Cost in USD": "التكلفة بالدولار الأمريكي",
    "Time Lost": "الوقت الضائع",
    "Money Lost": "المال المفقود",
    "The NTB Mobile App": "تطبيق NTB للهاتف المحمول",
    "This application has been designed to allow users who are citizens of the COMESA, EAC and SADC Regional Economic Communities (RECs) to report Non-Tariff Barriers (NTBs). Users that encounter NTBs whilst trading or trying to trade within these regions can log a complaint using the mobile app and the complaint will be sent to the relevant REC for review.": "تم تصميم هذا التطبيق للسماح للمستخدمين من مواطني الكوميسا ومجموعة شرق أفريقيا والمجموعات الاقتصادية الإقليمية (RECs) بالإبلاغ عن الحواجز غير الجمركية (NTBs). يمكن للمستخدمين الذين يواجهون عوائق غير تعريفية أثناء التداول أو محاولة التداول داخل هذه المناطق تسجيل شكوى باستخدام تطبيق الهاتف المحمول وسيتم إرسال الشكوى إلى اللجنة الاقتصادية الإقليمية ذات الصلة للمراجعة.",
    "The app is available for use by the following COMESA, EAC and SADC countries: Angola, Botswana, Burundi, Comoros, Democratic Republic of the Congo, Djibouti, Egypt, Eritrea, Eswatini, Ethiopia, Kenya, Lesotho, Libya, Madagascar, Malawi, Mauritius, Mozambique, Namibia, Rwanda, Seychelles, Somalia, South Africa, South Sudan, Sudan, Tanzania, Tunisia, Uganda, Zambia and Zimbabwe.": "التطبيق متاح للاستخدام من قبل دول الكوميسا التالية: أنجولا، بوتسوانا، بوروندي، جزر القمر، جمهورية الكونغو الديمقراطية، جيبوتي، مصر، إريتريا، سوازيلاند، إثيوبيا، كينيا، ليسوتو، ليبيا، مدغشقر، ملاوي، موريشيوس، موزمبيق، ناميبيا ورواندا وسيشيل والصومال وجنوب أفريقيا وجنوب السودان والسودان وتنزانيا وتونس وأوغندا وزامبيا وزيمبابوي.",
    "How to log a complaint": "كيفية تسجيل شكوى",
    "This app allows you to log and track a complaint that you think might be an NTB.": "يتيح لك هذا التطبيق تسجيل وتتبع الشكوى التي تعتقد أنها قد تكون بمثابة حظر غير قانوني.",
    "During the process of logging a complaint you will be asked a few questions - please be assured that your anonymity will be ensured, and data protected.": "أثناء عملية تسجيل الشكوى، سيتم طرح بعض الأسئلة عليك - يرجى التأكد من أنه سيتم ضمان عدم الكشف عن هويتك وحماية البيانات.",
    "The information you provide will be sent to the relevant REC administrator who will then work to resolve the issue.": "سيتم إرسال المعلومات التي تقدمها إلى مسؤول REC المعني الذي سيعمل بعد ذلك على حل المشكلة.",
    "You can track the status of your complaint using this app through the Dashboard screen. You may log new complaints at anytime.": "يمكنك تتبع حالة شكواك باستخدام هذا التطبيق من خلال شاشة لوحة المعلومات. يمكنك تسجيل شكاوى جديدة في أي وقت.",
    "For more information refer to the FAQ section.": "لمزيد من المعلومات راجع قسم الأسئلة الشائعة",
    "Welcome back": "مرحبًا بعودتك",
    "Unauthorized!": "غير مصرح!",
    "Email Address": "عنوان البريد الإلكتروني",
    "Submit": "يُقدِّم",
    "Submitting": "تقديم",
    "Password": "كلمة المرور",
    "Forgot your Password?": "نسيت كلمة السر؟",
    "Logging in": "تسجيل الدخول",
    "First Name": "الاسم الأول",
    "Last Name": "اسم العائلة",
    "Cellphone Number": "رقم الهاتف المحمول",
    "Telephone Number": "رقم هاتف",
    "Sector": "قطاع",
    "Other Sector": "قطاع آخر",
    "Country of Residence": "بلد الإقامة",
    "Operator Type": "نوع المشغل",
    "Other Operator": "مشغل آخر",
    "Gender": "جنس",
    "Update Password": "تطوير كلمة السر",
    "Confirm Password": "تأكيد كلمة المرور",
    "Update": "تحديث ",
    "Profile updated": "تحديث الملف الشخصي",
    "Female": "أنثى",
    "Male": "ذكر",
    "Loading": "تحميل",
    "Updating": "تحديث",
    "Registering": "التسجيل",
    "Registration complete": "اكتمل التسجيل",
    "About": "عن ",
    "Logout": "تسجيل خروج",
    "Error": "خطأ",
    "An error has occurred, please try again": "لقد حدث خطأ، يرجى المحاولة مرة أخرى",
    "Continue": "يكمل",
    "Connected": "متصل",
    "Choose your language": "اختر لغتك",
    "Airport": "مطار",
    "Border post": "البريد الحدودي",
    "Seaport": "ميناء",
    "Government institution": "مؤسسة حكومية",
    "Non-government institution": "مؤسسة غير حكومية",
    "Road block": "كتلة الطريق",
    "Weighbridge": "الميزان",
    "Government Policy and regulations": "السياسات واللوائح الحكومية",
    "Administrative (Border Operating Hours, delays at ...": "الإدارية (ساعات العمل على الحدود، والتأخير في ...",
    "Immigration requirements (Visa, travel permit)": "متطلبات الهجرة (التأشيرة، تصريح السفر)",
    "Transport related corruption": "الفساد المتعلق بالنقل",
    "Infrastructure (Air, Port, Rail, Road, Border Post...": "البنية التحتية (الهواء، الموانئ، السكك الحديدية، الطرق، مراكز الحدود...",
    "Vehicle standards": "معايير المركبات",
    "Issues related to transit": "القضايا المتعلقة بالعبور",
    "Customs Clearance Procedures": "إجراءات التخليص الجمركي",
    "Import/Export Regulations": "لوائح الاستيراد/التصدير",
    "Non-Tariff Fees": "الرسوم غير الجمركية",
    "Sanitary and Phytosanitary Measures": "تدابير الصحة والصحة النباتية",
    "Technical Barriers to Trade": "الحواجز الفنية أمام التجارة",
    "Transit Fees": "رسوم العبور",
    "Government participation in trade & restrictive pr...": "مشاركة الحكومة في التجارة والمنتجات التقييدية",
    "Customs and administrative entry procedures": "إجراءات الدخول الجمركية والإدارية",
    "Technical barriers to trade (TBT)": "الحواجز التقنية أمام التجارة (TBT)",
    "Sanitary & phyto-sanitary (SPS) measures": "التدابير الصحية والصحة النباتية (SPS).",
    "Specific limitations": "قيود محددة",
    "Charges on imports": "الرسوم على الواردات",
    "Other procedural problems": "مشاكل إجرائية أخرى",
    "Export subsidies": "دعم الصادرات",
    "Government monopoly in export/import": "احتكار الحكومة للتصدير/الاستيراد",
    "State subsidies, procurement, trading, state owner...": "إعانات الدولة، المشتريات، التجارة، مالك الدولة...",
    "Transport, Clearing and Forwarding": "النقل والتخليص والشحن",
    "Preference given to domestic bidders/suppliers": "تعطى الأفضلية لمقدمي العروض/الموردين المحليين",
    "Requirement for counter trade": "متطلبات التجارة المضادة",
    "Domestic assistance programmes for companies": "برامج المساعدة المنزلية للشركات",
    "Discriminatory or flawed government procurement po...": "سياسات المشتريات الحكومية التمييزية أو المعيبة...",
    "Import bans": "حظر الاستيراد",
    "Determination of eligibility of an exporting count...": "تحديد أهلية عدد المصدرين...",
    "Determination of eligibility of an exporting estab...": "تحديد أهلية المنشأة المصدرة...",
    "Occupational safety and health regulation": "تنظيم السلامة والصحة المهنية",
    "Multiplicity and Controls of Foreign exchange mark...": "تعدد وضوابط علامة النقد الأجنبي...",
    "\"Buy national\" policy": "سياسة \"شراء الوطنية\".",
    "Lack of coordination between government institutio...": "غياب التنسيق بين المؤسسات الحكومية..",
    "Other": "آخر",
    "Government imposing antidumping duties": "الحكومة تفرض رسوم مكافحة الإغراق",
    "Arbitrary customs classification": "التصنيف الجمركي التعسفي",
    "Issues related to the rules of origin": "المسائل المتعلقة بقواعد المنشأ",
    "Import licensing": "ترخيص الاستيراد",
    "Decreed customs surcharges": "الرسوم الجمركية الإضافية المقررة",
    "Additional taxes and other charges": "الضرائب الإضافية والرسوم الأخرى",
    "International taxes and charges levied on imports ...": "الضرائب والرسوم الدولية المفروضة على الواردات...",
    "Lengthy and costly customs clearance procedures": "إجراءات التخليص الجمركي طويلة ومكلفة",
    "Issues related to transit fees": "القضايا المتعلقة برسوم العبور",
    "Inadequate or unreasonable customs procedures and ...": "إجراءات جمركية غير كافية أو غير معقولة و...",
    "Lack of control in Customs infrastructure": "غياب الرقابة على البنية التحتية الجمركية",
    "Lack of capacity of Customs officers": "نقص قدرات موظفي الجمارك",
    "Issues related to Pre-Shipment Inspections": "القضايا المتعلقة بعمليات التفتيش قبل الشحن",
    "Restrictive technical regulations and standards no...": "اللوائح والمعايير الفنية المقيدة رقم ...",
    "Inadequate or unreasonable testing and certificati...": "الاختبارات والشهادات غير كافية أو غير معقولة.",
    "Standards disparities": "التفاوت في المعايير",
    "Intergovernmental acceptance of testing methods an...": "القبول الحكومي الدولي لطرق الاختبار ...",
    "Issues related to packaging, labeling and marking": "القضايا المتعلقة بالتعبئة والتغليف ووضع العلامات ووضع العلامات",
    "Conformity assessment related to TBT": "تقييم المطابقة المتعلق بالحواجز التقنية أمام التجارة",
    "Inadequate infrastructure": "بنية تحتية غير كافية",
    "Issues related to sanitary and phyto-sanitary measures (SPS)": "القضايا المتعلقة بالتدابير الصحية والصحة النباتية (SPS)",
    "Conformity assessment related to SPS": "تقييم المطابقة المتعلق بـ SPS",
    "Quantitative restrictions": "القيود الكمية",
    "Exchange controls": "ضوابط الصرف",
    "Export taxes": "ضرائب التصدير",
    "Quotas": "الحصص",
    "Import licensing requirements": "متطلبات ترخيص الاستيراد",
    "Proportion restrictions of foreign to domestic goods...": "القيود المفروضة على السلع الأجنبية والمحلية ...",
    "Minimum import price limits": "الحدود الدنيا لأسعار الاستيراد",
    "Embargoes": "الحظر",
    "Non-automatic licensing": "الترخيص غير التلقائي",
    "Prohibitions": "المحظورات",
    "Quantitative safeguard measures": "تدابير الحماية الكمية",
    "Export restraint arrangements": "ترتيبات تقييد التصدير",
    "Other quantity control measures": "تدابير أخرى لمراقبة الكمية",
    "Restrictive licenses": "تراخيص مقيدة",
    "Prior import deposits and subsidies": "ودائع الاستيراد والإعانات السابقة",
    "Administrative fees": "الرسوم الإدارية",
    "Special supplementary duties": "الواجبات التكميلية الخاصة",
    "Import credit discriminations": "التمييز الائتماني في الاستيراد",
    "Variable levies": "الرسوم المتغيرة",
    "Border taxes": "ضرائب الحدود",
    "Arbitrariness": "التعسف",
    "Discrimination": "تمييز ",
    "Corruption": "فساد",
    "Costly procedures": "إجراءات مكلفة",
    "Lengthy procedures": "إجراءات مطولة",
    "Lack of information on procedures (or changes ther...": "نقص المعلومات حول الإجراءات (أو التغييرات فيها)",
    "Complex variety of documentation required": "مجموعة متنوعة معقدة من الوثائق المطلوبة",
    "Consular and Immigration Issues": "القضايا القنصلية والهجرة",
    "Inadequate trade related infrastructure": "عدم كفاية البنية التحتية المتعلقة بالتجارة",
    "Costly Road user charges /fees": "رسوم/رسوم مستخدم الطريق المكلفة",
    "Nothing": "لا شئ",
    "Informal trader": "تاجر غير رسمي",
    "Small scale trader": "تاجر صغير الحجم",
    "Commercial trader": "تاجر تجاري",
    "Transporter": "الناقل",
    "Clearing agent or freight forwarder": "وكيل التخليص أو وكيل الشحن",
    "This is the first time": "هذه اول مرة",
    "Once before": "مرة من قبل",
    "A few times": "بعض الاوقات",
    "Every month": "كل شهر",
    "every day": "كل يوم",
    "$xx or above": "$xx أو أعلى",
    "X to X": "X إلى X",
    "Exact value of loss": "القيمة الدقيقة للخسارة",
    "Briefly describe how the loss has been calculated": "صف بإيجاز كيف تم حساب الخسارة",
    "Evidence": "شهادة",
    "Description of how the loss was calculated": "وصف لكيفية حساب الخسارة",
    "Message": "رسالة",
    "Loading complaint...": "جارٍ تحميل الشكوى...",
    "Product description": "وصف المنتج",
    "Search for Tariff Codes": "البحث عن رموز التعريفة",
    "PRODUCT DESCRIPTION OR TARIFF CODE": "وصف المنتج أو رمز التعريفn",
    "VALUE OF LOSS IN USD": "قيمة الخسارة بالدولار الأمريكي",
    "COMPLAINT DESCRIPTION": "وصف الشكوى",
    "Cancel": "يلغي",
    "Frequently Asked Questions": "أسئلة مكررة",
    "Step": "خطوة",
    "of": "من",
    "Edit": "يحرر",
    "Tariff Codes": "رموز التعريفة",
    "Change": "يتغير",
    "Value of consignment": "قيمة الشحنة",
    "Currency": "عملة",
    "Age": "عمر",
    "Create New Complaint": "إنشاء شكوى جديدة",
    "Change Language": "تغيير اللغة",
};

module.exports = {ara};
