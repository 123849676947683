<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="2">
          <v-card-title class="py-1">{{ t("Notifications") }}</v-card-title>
          <v-card-text>
            <p>{{ t("Important message to all users") }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!showComplaints && !loading">
      <v-col cols="12">
        <message-alert
          :message="t('No Complaints')"
          alert-type="info"
        ></message-alert>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="showComplaints">
      <v-col cols="12">
        <v-card elevation="2" class="mx-auto">
          <v-card-title class="py-1">{{ t("My Complaints") }}</v-card-title>
          <v-card-text class="pa-0">
            <v-list two-line>
              <v-list-item-group>
                <template v-for="(complaint, index) in complaints">
                  <v-list-item :key="complaint.id">
                    <v-list-item-content>
                      <v-list-item-subtitle class="font-weight-bold">
                        {{ complaint.status.name }}
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{
                          formatDate(
                            complaint.date_of_incident,
                            "dddd, DD MMMM YYYY"
                          )
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text--primary">
                        {{
                          location(
                            complaint.location,
                            complaint.location_other
                          )
                        }}, {{ complaint.country.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ complaint.ntb_type.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-badge
                        bordered
                        color="error"
                        :content="unreadMessages(complaint.id)"
                        overlap
                      >
                        <v-btn
                          small
                          color="#299347"
                          class="white--text"
                          block
                          @click="showComplaint(complaint.id)"
                        >
                          <v-icon small> mdi-card-search </v-icon>
                          {{ t("View") }}
                        </v-btn>
                        <v-btn
                          v-if="complaint.status.default === 1"
                          small
                          color="#f2a41f"
                          class="white--text mt-1"
                          block
                          @click="redirectToUpdateComplaint(complaint.id)"
                        >
                          <v-icon small> mdi-pencil </v-icon>
                          {{ t("Edit") }}
                        </v-btn>
                      </v-badge>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider
                    v-if="index < complaints.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-bottom-sheet v-if="viewComplaint" v-model="viewComplaint" persistent>
          <v-sheet scrollable class="overflow-y-auto rounded">
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0">
                <div class="text-right">
                  <v-btn
                    class="mt-3 mb-0"
                    text
                    color="black"
                    @click="hideComplaint"
                  >
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-card elevation="0">
              <v-card-text class="bottom-sheet-card">
                <p>
                  <strong>{{ t("NTB Type") }}: </strong>
                  {{ activeComplaint.ntb_type.name }}
                </p>
                <p>
                  <strong>{{ t("Date of Incident") }}: </strong>
                  {{
                    formatDate(
                      activeComplaint.date_of_incident,
                      "dddd, DD MMMM YYYY"
                    )
                  }}
                </p>
                <p>
                  <strong>{{ t("Status") }}: </strong>
                  {{
                    activeComplaint.status ? activeComplaint.status.name : ""
                  }}
                </p>
                <p>
                  <strong>{{ t("Country") }}: </strong>
                  {{ activeComplaint.country.name }}
                </p>
                <p>
                  <strong>{{ t("Location") }}: </strong>
                  {{
                    location(
                      activeComplaint.location,
                      activeComplaint.location_other
                    )
                  }}
                </p>
                <p v-if="activeComplaint.product_description">
                  <strong>{{ t("Product description") }}: </strong>
                  {{ activeComplaint.product_description }}
                </p>
                <p v-if="activeComplaint.currency_id && currency">
                  <strong>{{ t("Currency") }}: </strong>
                  {{ currency.name }} -
                  {{ currency.currency }}
                </p>
                <p v-if="activeComplaint.value">
                  <strong>{{ t("Value of consignment") }}: </strong>
                  {{ activeComplaint.value }}
                </p>
                <p>
                  <strong>{{ t("Complaint") }}: </strong>
                  {{ activeComplaint.complaint }}
                </p>
                <v-row v-if="reloadMessages">
                  <v-col cols="12">
                    <v-skeleton-loader
                      type="article, actions"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
                <div v-else>
                  <div v-if="activeComplaint.messages.length > 0">
                    <v-divider class="my-3"></v-divider>
                    <v-row
                      v-for="message in activeComplaint.messages"
                      :key="message.id"
                      :ref="message.id + '|' + activeComplaint.id"
                      :set="
                        (messageDetails = messageStatusContent(
                          activeComplaint.id,
                          message.id
                        ))
                      "
                    >
                      <v-col>
                        <div
                          class="d-flex flex-row my-2"
                          :class="
                            message.is_me === 'Yes' ? 'justify-end' : null
                          "
                        >
                          <v-badge
                            bordered
                            :color="messageDetails.color"
                            :icon="messageDetails.status"
                            overlap
                            :left="message.is_me === 'No'"
                          >
                            <v-card
                              :color="
                                message.is_me === 'Yes' ? '#b2dea5' : '#fccf82'
                              "
                            >
                              <v-card-text
                                class="max-width-message black--text"
                              >
                                <p class="caption">
                                  {{ getMessageUser(message) }}
                                </p>
                                <div class="my-4 font-weight-bold">
                                  {{ message.message }}
                                </div>
                                <p
                                  class="caption text-right mt-2 mb-0 font-italic"
                                >
                                  {{
                                    formatDate(
                                      message.created_at,
                                      "DD MMMM YYYY @ HH:mm"
                                    )
                                  }}
                                </p>
                              </v-card-text>
                            </v-card>
                          </v-badge>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <v-divider class="my-3"></v-divider>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="messageToSend"
                      :label="t('Message')"
                      auto-grow
                    >
                      <v-icon
                        slot="append"
                        @click="sendMessage"
                        :disabled="null === messageToSend"
                      >
                        mdi-send
                      </v-icon>
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-bottom-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  forEach,
  isEmpty,
  includes,
  filter,
  find,
  debounce,
  isUndefined,
} from "lodash";
import MessageAlert from "../../components/alert/MessageAlert";
import moment from "moment";
import "moment/locale/ar";
import "moment/locale/fr";
import { mapErrors } from "@/lib/formUtils";

export default {
  name: "ComplaintsView",
  components: { MessageAlert },
  data() {
    return {
      errorMessage: null,
      complaints: [],
      viewComplaint: false,
      activeComplaint: null,
      loading: true,
      messageToSend: null,
      reloadMessages: false,
      reloadMessagesInterval: null,
      reloadComplaintsInterval: null,
      updatedMessages: [],
      complaintMessages: [],
    };
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.userDetails,
      token: (state) => state.token,
      lookups: (state) => state.lookups,
    }),
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      shortLang: "shortLang",
    }),
    showComplaints() {
      return !isEmpty(this.complaints);
    },
    currency() {
      const that = this;

      return find(this.lookups.countries, function (o) {
        return o.id === that.activeComplaint.currency_id;
      });
    },
  },
  async mounted() {
    const that = this;
    clearInterval(this.reloadMessagesInterval);
    clearInterval(this.reloadComplaintsInterval);

    if (this.isLoggedIn) {
      await this.getComplaints();

      this.reloadComplaintsInterval = setInterval(function () {
        that.getComplaints();
      }, 300000);
    } else {
      await that.$router.push({
        name: "login",
        query: {
          popup: "true",
          message: that.t("Unauthorized!"),
          type: "error",
        },
      });
    }
  },
  watch: {
    viewComplaint(value) {
      if (false === value) {
        clearInterval(this.reloadMessagesInterval);
      }
    },
  },
  created() {
    window.addEventListener("wheel", debounce(this.onScroll, 100));
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.onScroll);
    clearInterval(this.reloadMessagesInterval);
    clearInterval(this.reloadComplaintsInterval);
  },
  methods: {
    redirectToUpdateComplaint(id) {
      this.$router.push({ name: "complaints-log", params: { id: id } });
    },
    async getComplaints() {
      const that = this;

      this.$store
        .dispatch("viewComplaints", {
          userId: this.userDetails.id,
          token: this.token,
        })
        .then((response) => {
          that.complaints = response.data.data;

          forEach(that.complaints, function (complaint) {
            that.$set(that.complaintMessages, complaint.id, complaint.messages);
          });
        })
        .catch((error) => {
          mapErrors(error.data);
        })
        .finally(() => {
          that.loading = false;
        });
    },
    unreadMessages(id) {
      const unreadMessages = filter(
        this.complaintMessages[id],
        function (message) {
          return message.is_me === "No" && message.read === 0;
        }
      );

      return unreadMessages.length + "";
    },
    messageStatusContent(complaintId, id) {
      let statusDetails = {
        status: "mdi-account",
        color: "#299347",
      };
      const that = this;

      const message = filter(
        that.complaintMessages[complaintId],
        function (message) {
          return message.is_me === "No" && message.id === id;
        }
      );

      if (!isUndefined(message) && !(message.length === 0)) {
        statusDetails.color = message.is_me ? "#299347" : "#f2a41f";

        if (message[0].read) {
          statusDetails.status = "mdi-email-open";
        }

        if (!message[0].read) {
          statusDetails.status = "mdi-email-mark-as-unread";
        }
      }

      return statusDetails;
    },
    messageRead(element) {
      return (
        element.top >= 0 &&
        element.left >= 0 &&
        element.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        element.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    onScroll() {
      const that = this;

      forEach(that.$refs, function (messageElement, id) {
        const ids = id.split("|"),
          messageId = parseInt(ids[0]),
          complaintId = ids[1];
        if (!includes(that.updatedMessages, messageId)) {
          if (!isUndefined(messageElement[0])) {
            const element = messageElement[0].getBoundingClientRect();

            if (that.messageRead(element)) {
              that.markAsRead(messageId, complaintId);
            }
          }
        }
      });
    },
    async markAsRead(id, complaintId) {
      const that = this,
        messageToMark = find(that.complaintMessages[complaintId], {
          id: id,
        });

      if (!isUndefined(messageToMark) && !messageToMark.read) {
        this.$store.dispatch("markAsRead", { id: id }).then(() => {
          that.updatedMessages.push(id);
          find(that.complaintMessages[complaintId], {
            id: id,
          }).read = true;
          that.complaintMessages = Object.assign({}, that.complaintMessages);
        });
      } else {
        that.updatedMessages.push(id);
      }
    },
    async callComplaint(id) {
      const that = this;

      this.$store
        .dispatch("viewComplaint", {
          complaintId: id,
          token: this.token,
        })
        .then((response) => {
          that.activeComplaint = response.data.data;
          that.viewComplaint = true;
        })
        .catch((error) => {
          mapErrors(error.data);
        })
        .finally(() => {
          that.reloadMessages = false;
        });
    },
    async showComplaint(id) {
      const that = this;
      await this.callComplaint(id);
      clearInterval(this.reloadMessagesInterval);

      this.reloadMessagesInterval = setInterval(function () {
        that.callComplaint(id);
      }, 10000);
    },
    async sendMessage() {
      const that = this;
      this.reloadMessages = true;

      this.$store
        .dispatch("sendMessage", {
          data: {
            message: this.messageToSend,
            token: this.token,
          },
          id: that.activeComplaint.id,
        })
        .then(() => {
          that.messageToSend = null;
          that.showComplaint(that.activeComplaint.id);
        })
        .catch((error) => {
          mapErrors(error.data);
        });
    },
    hideComplaint() {
      this.viewComplaint = false;
      clearInterval(this.reloadMessagesInterval);
    },
    formatDate(date, format) {
      moment.locale(this.shortLang);
      return moment.parseZone(date).format(format);
    },
    location(details, otherLocation) {
      if (isEmpty(details)) {
        return otherLocation;
      } else {
        return details.name;
      }
    },
    getMessageUser(message) {
      return isEmpty(message.user)
        ? ""
        : message.user.first_name + " " + message.user.last_name;
    },
  },
};
</script>

<style scoped>
.bottom-sheet-card {
  height: 60vh !important;
}
.max-width-message {
  max-width: 275px !important;
}
</style>
