import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { includes, isUndefined } from "lodash";
import { theApp } from "@/main";

Vue.use(Vuex);

const baseUrl = process.env.VUE_APP_API_URL;
const successfulResponses = [200, 422, 400];

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  state: {
    lookups: null,
    faq: null,
    userDetails: {},
    token: null,
    apiError: false,
    lang: "eng",
  },
  actions: {
    async callFaq({ commit, state }, data) {
      if (null === state.faq || data.force) {
        return new Promise((resolve, reject) => {
          instance
            .get("api/faq/" + state.lang)
            .then((response) => {
              commit("setFaq", response.data.data);
              resolve(response);
            })
            .catch((error) => {
              commit("determineApiError", error.response);
              if (error.response) {
                reject(error.response);
              }

              reject(error);
            });
        });
      }
    },
    async callLookups({ commit, state }, data) {
      if (null === state.lookups || data.force) {
        return new Promise((resolve, reject) => {
          instance
            .get("api/lookups/" + state.lang)
            .then((response) => {
              commit("setLookups", response.data.data);
              resolve(response);
            })
            .catch((error) => {
              commit("determineApiError", error.response);
              if (error.response) {
                reject(error.response);
              }

              reject(error);
            });
        });
      }
    },
    async wizard({ commit, state }, { data, token }) {
      return new Promise((resolve, reject) => {
        instance
          .post("api/complaints/wizard", data, {
            headers: {
              Authorization: "Bearer " + token,
              locale: state.lang,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("determineApiError", error.response);
            if (error.response) {
              reject(error.response);
            }

            reject(error);
          });
      });
    },
    async viewComplaints({ commit }, { userId, token }) {
      return new Promise((resolve, reject) => {
        instance
          .get("api/complaints/view/" + userId, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("determineApiError", error.response);
            if (error.response) {
              reject(error.response);
            }

            reject(error);
          });
      });
    },
    async deleteDocument({ commit }, { documentId, token }) {
      return new Promise((resolve, reject) => {
        instance
          .get("api/document/delete/" + documentId, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("determineApiError", error.response);
            if (error.response) {
              reject(error.response);
            }

            reject(error);
          });
      });
    },
    async downloadDocument({ commit }, { documentId, token }) {
      return new Promise((resolve, reject) => {
        instance
          .get("api/document/download/" + documentId, {
            headers: {
              Authorization: "Bearer " + token,
            },
            responseType: "blob",
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("determineApiError", error.response);
            if (error.response) {
              reject(error.response);
            }

            reject(error);
          });
      });
    },
    async viewComplaint({ commit }, { complaintId, token }) {
      return new Promise((resolve, reject) => {
        instance
          .get("api/complaint/view/" + complaintId, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("determineApiError", error.response);
            if (error.response) {
              reject(error.response);
            }

            reject(error);
          });
      });
    },
    async sendMessage({ commit, state }, { data, id }) {
      return new Promise((resolve, reject) => {
        instance
          .post("api/complaint/" + id + "/message", data, {
            headers: {
              Authorization: "Bearer " + state.token,
              locale: state.lang,
            },
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            commit("determineApiError", error.response);
            if (error.response) {
              reject(error.response);
            }

            reject(error);
          });
      });
    },
    async register({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        instance
          .post("api/auth/register", data, {
            headers: {
              locale: state.lang,
            },
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            commit("determineApiError", error.response);
            if (error.response) {
              reject(error.response);
            }

            reject(error);
          });
      });
    },
    async markAsRead({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        instance
          .post("api/messages/read", data, {
            headers: {
              Authorization: "Bearer " + state.token,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("determineApiError", error.response);
            if (error.response) {
              reject(error.response);
            }

            reject(error);
          });
      });
    },
    async updateProfile({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        instance
          .post("api/auth/update-profile", data, {
            headers: {
              Authorization: "Bearer " + state.token,
              locale: state.lang,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("determineApiError", error.response);
            if (error.response) {
              reject(error.response);
            }

            reject(error);
          });
      });
    },
    async forgotPassword({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        instance
          .post("api/auth/forgot-password", data, {
            headers: {
              locale: state.lang,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("determineApiError", error.response);
            if (error.response) {
              reject(error.response);
            }

            reject(error);
          });
      });
    },
    async login({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        instance
          .post("api/auth/login", data, {
            headers: {
              locale: state.lang,
            },
          })
          .then((response) => {
            commit("setToken", response.data.data.token);
            resolve(response);
          })
          .catch((error) => {
            commit("determineApiError", error.response);
            if (error.response) {
              reject(error.response);
            }
            reject(error);
          });
      });
    },
    async userDetails({ commit, state }) {
      return new Promise((resolve, reject) => {
        instance
          .get("api/user", {
            headers: {
              Authorization: "Bearer " + state.token,
            },
          })
          .then((response) => {
            commit("setDetails", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("determineApiError", error.response);
            if (error.response) {
              reject(error.response);
            }

            reject(error);
          });
      });
    },
    async logout({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        instance
          .post("api/auth/logout", data, {
            headers: {
              Authorization: "Bearer " + state.token,
            },
          })
          .then((response) => {
            commit("setToken", null);
            resolve(response);
          })
          .catch((error) => {
            commit("determineApiError", error.response);
            if (error.response) {
              reject(error.response);
            }

            reject(error);
          });
      });
    },
  },
  mutations: {
    setFaq(state, faq) {
      state.faq = faq;
    },
    setLookups(state, lookups) {
      state.lookups = lookups;
    },
    setDetails(state, details) {
      state.userDetails = details;
    },
    setToken(state, token) {
      state.token = token;
    },
    determineApiError(state, response) {
      if (401 === response.status) {
        state.token = null;

        if ("login" !== theApp.$route.name) {
          return theApp.$router.push({
            name: "login",
            query: {
              popup: "true",
              message: theApp.t("Unauthorized!"),
              type: "error",
            },
          });
        } else {
          return;
        }
      }

      state.apiError = isUndefined(response)
        ? true
        : !includes(successfulResponses, response.status);
    },
    setApiError(state, error) {
      state.apiError = error;
    },
    setLang(state, lang) {
      state.lang = lang;
    },
  },
  getters: {
    isLoggedIn(state) {
      return null !== state.token;
    },
    shortLang(state) {
      return state.lang.substring(0, 2);
    },
  },
});
