<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="step5.currency_id"
          :items="currencyList"
          :label="t('Currency')"
          :error-messages="getErrorMessages('currency_id')"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field-money
          v-model="step5.value"
          :label="t('Value of consignment')"
          v-bind:properties="{
            readonly: false,
            disabled: false,
            outlined: false,
            clearable: true,
            errorMessages: getErrorMessages('value'),
          }"
          v-bind:options="{
            length: 10,
            precision: 2,
            empty: '0',
          }"
          @change="change"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty, isNull, map } from "lodash";
import MessageAlert from "../../../components/alert/MessageAlert";

export default {
  name: "WizardStep5",
  components: { MessageAlert },
  emits: ["values-changed", "set-title"],
  props: {
    valueEntered: {
      type: String,
      default: null,
    },
    currencySelected: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      title: "VALUE OF CONSIGNMENT",
      step: 5,
      step5: {
        value: "0",
        currency_id: null,
      },
    };
  },
  computed: {
    ...mapState({
      lookups: (state) => state.lookups,
    }),
    currencyList() {
      return map(
        !isNull(this.lookups.countries) ? this.lookups.countries : [],
        function (lookup) {
          return {
            text: lookup.name + " - " + lookup.currency,
            value: lookup.id,
          };
        }
      );
    },
  },
  mounted() {
    this.step5.value = "null" === this.valueEntered ? "0" : this.valueEntered;
    this.step5.currency_id = this.currencySelected;

    this.$emit("values-changed", { data: this.step5, step: this.step });
    this.$emit("set-title", { title: this.title });
  },
  methods: {
    change() {
      this.$emit("values-changed", { data: this.step5, step: this.step });
    },
    hasError(field) {
      return !isEmpty(this.errors[field]);
    },
    getErrorMessages(field) {
      return this.errors[field];
    },
  },
};
</script>

<style scoped>
.no-shadow {
  box-shadow: none !important;
  margin: 0px !important;
  border-radius: 0px !important;
}
</style>
