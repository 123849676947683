<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea
          v-model="step4.product_description"
          :label="t('Product description')"
          :error-messages="getErrorMessages('product_description')"
          @change="change"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { isEmpty } from "lodash";
import MessageAlert from "../../../components/alert/MessageAlert";

export default {
  name: "WizardStep4",
  components: { MessageAlert },
  emits: ["values-changed", "set-title"],
  props: {
    productDescriptionEntered: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      title: "PRODUCT DESCRIPTION",
      step: 4,
      step4: {
        product_description: null,
      },
    };
  },
  mounted() {
    this.step4.product_description = this.productDescriptionEntered;

    this.$emit("values-changed", { data: this.step4, step: this.step });
    this.$emit("set-title", { title: this.title });
  },
  methods: {
    change() {
      this.$emit("values-changed", { data: this.step4, step: this.step });
    },
    hasError(field) {
      return !isEmpty(this.errors[field]);
    },
    getErrorMessages(field) {
      return this.errors[field];
    },
  },
};
</script>

<style scoped>
.no-shadow {
  box-shadow: none !important;
  margin: 0px !important;
  border-radius: 0px !important;
}
</style>
